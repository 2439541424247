
<script>
  import {Bar} from 'vue-chartjs'
  import axios from "axios";  
  export default {
    extends:  Bar,
    data() {
      return {
        chartData: {
          labels: [],
          datasets: [{
            label: 'Book Chart',
            borderWidth: 1,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
              'rgba(255,99,132,1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255,99,132,1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
            ],
            data: []
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
            display: false
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    mounted() {
    this.getbookdata()
    },
    methods: {
    getbookdata() {
        axios
            .post("/IssueBook/getBookData")
            .then((res) => {
                if (res.data.msg == "200") {
                    this.chartData.datasets[0].data=res.data.data
                     this.chartData.labels=res.data.bookStatusname
                    //alert(this.chartData.datasets[0].data)
                    this.renderChart(this.chartData, this.options)
                }
            })
            .catch((error) => {
                this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                window.console.log(error);
            })
            .finally(() => {
            });
    },
    }
  }
</script>